/**
 * 进行local数据存储管理的工具模块
 * 也有store的插件可以用:import store from 'store
 */
const USER_KEY = 'hp_sales_country'
const storage = {
    //  保存
    saveUser (user) {
        localStorage.setItem(USER_KEY, JSON.stringify(user))
        // store.set(USER_KEY,user)
    },
    // 读取
    getUser () {
        return JSON.parse(localStorage.getItem(USER_KEY) || '{}')
        // return store.get(USER_KEY) || {}
    },
    // 删除
    removeUser () {
        localStorage.removeItem(USER_KEY)
        // store.remove(USER_KEY)
    }
}
export default storage

