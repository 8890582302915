import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/css/reset.css'
import '@/assets/css/common.scss'
import * as filters from './utils/filters.js'
import '@/utils/utils'

import {
  Container,
  Aside,
  Header,
  Main,
  Menu,
  Submenu,
  MenuItem,
  MenuItemGroup,
  DatePicker,
  Button,
  Table,
  TableColumn,
  Pagination,
  Form,
  FormItem,
  Input,
  Card,
  Timeline,
  TimelineItem,
  Steps,
  Step,
  Select,
  Option,
  CheckboxGroup,
  Checkbox,
  Upload,
  Dialog,
  Breadcrumb,
  BreadcrumbItem,
  Col,
  Image,
  Badge,
  Divider,
  TimePicker,
  Progress
} from 'element-ui'

Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})
Vue.config.productionTip = false
Vue.use(Container).use(Aside).use(Header).use(Main).use(Menu).use(Submenu).use(MenuItem).use(MenuItemGroup)
  .use(DatePicker).use(Button).use(Table).use(TableColumn).use(Pagination).use(Form).use(FormItem).use(Input).use(Card)
  .use(Timeline).use(TimelineItem).use(Steps).use(Step).use(Select).use(Option).use(CheckboxGroup).use(Checkbox).use(Upload).use(Dialog)
  .use(Breadcrumb).use(BreadcrumbItem).use(Col).use(Image).use(Badge).use(Divider).use(TimePicker).use(Progress)


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
