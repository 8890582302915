import Storage from '@/utils/storage'

// 国家转中文
export const CouZH = (str) => {
    const objCou = Storage.getUser()
    if (!str) return '-'
    if (str === '*') {
      return '其他国家'
    } else if (str === 'base') {
      return '基础收费'
    } else if (str === 'SERVICE') {
      return '服务贸易'
    } else if (str === 'CARGO') {
      return '货物贸易'
    } else if (str === 'EUU') {
      return '欧盟'
    } else {
      return objCou[str]
    }
  }