import Cookies from 'js-cookie'

const KEY = 'hp_sale_token'
const authToken = {
  getToken: function () {
    return Cookies.get(KEY)
  },
  setToken: function (token) {
    Cookies.set(KEY, token)
  },
  removeToken: function () {
    Cookies.remove(KEY)
  }
}
export default authToken



// business_country_code: '',
        // company_name: '',
        // register_certificate: '',
        // register_country: '',
        // merchant_short_name: '',
        // business_call: '',
        // amount_average: '',
        // service_phone: '',
        // merchant_describe: '',
        // merchant_remark: '',
        // merchant_offline: ['offline'],
        // contact_name: '',
        // contact_email: '',
        // contact_phone: '',
        // wx_channel: [], //微信支付通道
        // wx_industry_list: [], //行业大类，行业细类
        // wx_currency_list: { online: [], offline: [] }, //微信线上线下结算币种
        // ali_channel: [], //支付宝支付通道
        // ali_industry_list: [], //商户大类，商户细类，通用范围
        // ali_currency_list: { online: [], offline: [] }, //支付宝线上线下结算币种
        // merchant_url: '',
        // merchant_store_address: '',
        // merchant_service_begin_time: '',
        // merchant_service_end_time: '',
        // merchant_store_pic: []
