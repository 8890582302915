import Vue from 'vue'
import VueRouter from 'vue-router'
import Cookie from '@/utils/cookie'
// 解决router报错：Uncaught (in promise) NavigationDuplicated: Avoided redundant navigation to current location: "/login"
const routerPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
return routerPush.call(this, location).catch(error=> error)
}
Vue.use(VueRouter)

const routes = [
  // {
  //   path:'/',
  //   redirect:'/merchant'
  // },
  {
    path: '/login',
    name: 'Login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "login" */ '../views/login.vue')
  },
  {
    path: '/',
    name: 'Layout',
    redirect: '/login',
    component: () => import('@/components/layout'),
    children: [
      {
        path: 'merchant',
        name: 'Merchant',
        meta: { title: '商户管理', icon: 'el-icon-set-up' },
        redirect: '/merchant/list',
        component: () => import('@/views/blank'),
        children: [
          {
            path: 'list',
            name: 'MerchantList',
            meta: { title: '商户列表', hidden: true },
            component: () => import(/* webpackChunkName: "MerchantList" */ '@/views/merchant/list')
          },
          {
            path: 'detail',
            name: 'MerchantDetail',
            meta: { title: '商户详情', hidden: true },
            component: () => import(/* webpackChunkName: "MerchantDetail" */ '@/views/merchant/detail')
          },
          {
            path: 'data-review',
            name: 'DataReview',
            meta: { title: '资料审核', hidden: true },
            component: () => import(/* webpackChunkName: "DataReview" */ '@/views/merchant/data-review')
          },
          {
            path: 'contract',
            name: 'Contract',
            meta: { title: '提交合同', hidden: true },
            component: () => import(/* webpackChunkName: "Contract" */ '@/views/merchant/contract')
          },
        ]
      },
      {
        path: 'drafts',
        name: 'Drafts',
        meta: { title: '草稿箱', icon: 'el-icon-takeaway-box' },
        component: () => import(/* webpackChunkName: "Drafts" */ '@/views/drafts')
      },
      {
        path: 'news',
        name: 'News',
        meta: { title: '消息', icon: 'el-icon-news' },
        component: () => import(/* webpackChunkName: "News" */ '@/views/news')
      },
      {
        path: 'bill',
        name: 'Bill',
        meta: { title: '商户流水', icon: 'el-icon-tickets' },
        component: () => import(/* webpackChunkName: "Bill" */ '@/views/bill')
      },
      {
        path: 'statistics',
        name: 'Statistics',
        meta: { title: '商户统计', icon: 'el-icon-notebook-2' },
        component: () => import(/* webpackChunkName: "Statistics" */ '@/views/statistics')
      },
      // {
      //   path: 'test',
      //   name: 'Test',
      //   meta: { title: '测试' },
      //   redirect: '/test/son1',
      //   component: () => import('@/views/blank'),
      //   children: [  //子菜单列表         
      //     {
      //       path: 'son1',
      //       name: 'Son1',
      //       meta: { title: '儿子1' },

      //       component: () => import('@/views/statistics'),
      //       children: [
      //         {
      //           path: 'grandson1',
      //           name: 'Grandson1',
      //           meta: { title: '孙子1' },
      //           component: () => import('@/views/login')
      //         },
      //         {
      //           path: 'grandson2',
      //           name: 'Grandson2',
      //           meta: { title: '孙子2' },
      //           component: () => import('@/views/login')
      //         },
      //       ]
      //     },
      //     {
      //       path: 'son2',
      //       name: 'Son2',
      //       meta: { title: '儿子2' },
      //       component: () => import('@/views/bill')
      //     },
      //     {
      //       path: 'son3',
      //       name: 'Son3',
      //       meta: { title: '儿子3' },
      //       component: () => import('@/views/statistics')
      //     },
      //   ]
      // }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  const token = Cookie.getToken()
  if (token) {
    next()
  } else {
    if (to.name === 'Login') {
      next()
    } else {
      next('/login')
    }
  }
});
export default router